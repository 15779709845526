import React, { useState, useEffect, useCallback, forwardRef } from 'react';
import { Group, Text } from 'react-konva';

import Card from './Card';

function CardStack_({ value, centerX, y, setHeight, fontSize }, ref) {
  var [fontFamily, setFontFamily] = useState(null);
  const glyphPadding = 2;

  var [largestDims, setLargestDims] = useState({width: 0, height: 0});

  const useMeasuredGlyph = () => {
    const [dims, setDims] = useState({width: 0, height: 0});
    const ref = useCallback(node => {
      if (node !== null) {
        setDims({width: node.width(), height: node.height()});
      }
    }, []);
    return [dims, ref];
  };

  var [glyph0Dims, glyph0Ref] = useMeasuredGlyph();
  var [glyph1Dims, glyph1Ref] = useMeasuredGlyph();
  var [glyph2Dims, glyph2Ref] = useMeasuredGlyph();
  var [glyph3Dims, glyph3Ref] = useMeasuredGlyph();
  var [glyph4Dims, glyph4Ref] = useMeasuredGlyph();
  var [glyph5Dims, glyph5Ref] = useMeasuredGlyph();
  var [glyph6Dims, glyph6Ref] = useMeasuredGlyph();
  var [glyph7Dims, glyph7Ref] = useMeasuredGlyph();
  var [glyph8Dims, glyph8Ref] = useMeasuredGlyph();
  var [glyph9Dims, glyph9Ref] = useMeasuredGlyph();

  useEffect(() => {
    const dims = [glyph0Dims, glyph1Dims, glyph2Dims, glyph3Dims, glyph4Dims,
                  glyph5Dims, glyph6Dims, glyph7Dims, glyph8Dims, glyph9Dims];
    const largestWidth = Math.max.apply(null, dims.map((n,_) => n.width));
    const largestHeight = Math.max.apply(null, dims.map((n,_) => n.height));

    setLargestDims({width: largestWidth,
                    height: largestHeight});

    setHeight(largestHeight + 2*glyphPadding);
  }, [glyph0Dims, glyph1Dims, glyph2Dims, glyph3Dims, glyph4Dims,
      glyph5Dims, glyph6Dims, glyph7Dims, glyph8Dims, glyph9Dims,
      setHeight]);

  useEffect(() => {
    /*global fontLoader*/ //eslint-disable-line
    fontLoader = { //eslint-disable-line
      succeeded: (family) => setFontFamily(family),
      failed: () => setFontFamily("Monaco")
    };

  }, []);

  return (
    <Group x={centerX - (largestDims.width * 4 / 2)}
           y={y}
           visible={fontFamily != null}
           ref={ref}>
      <Card value={(value[1000] > 0) ? value[1000] : 0}
            fontFamily={fontFamily}
            fontSize={fontSize}
            magnitude={3}
            largestDims={largestDims}/>
      <Card value={(value[100] > 0) ? value[100] : 0}
            fontFamily={fontFamily}
            fontSize={fontSize}
            magnitude={2}
            largestDims={largestDims}/>
      <Card value={(value[10] > 0) ? value[10] : 0}
            fontFamily={fontFamily}
            fontSize={fontSize}
            magnitude={1}
            largestDims={largestDims}/>
      <Card value={(value[1] > 0) ? value[1] : 0}
            fontFamily={fontFamily}
            fontSize={fontSize}
            magnitude={0}
            largestDims={largestDims}/>

      <Text visible={false}
            text="0"
            glyph={0}
            fontFamily={fontFamily}
            fontSize={fontSize}
            padding={glyphPadding}
            ref={glyph0Ref}/>
      <Text visible={false}
            text="1"
            glyph={1}
            fontFamily={fontFamily}
            fontSize={fontSize}
            padding={glyphPadding}
            ref={glyph1Ref}/>
      <Text visible={false}
            text="2"
            glyph={2}
            fontFamily={fontFamily}
            fontSize={fontSize}
            padding={glyphPadding}
            ref={glyph2Ref}/>
      <Text visible={false}
            text="3"
            glyph={3}
            fontFamily={fontFamily}
            fontSize={fontSize}
            padding={glyphPadding}
            ref={glyph3Ref}/>
      <Text visible={false}
            text="4"
            glyph={4}
            fontFamily={fontFamily}
            fontSize={fontSize}
            padding={glyphPadding}
            ref={glyph4Ref}/>
      <Text visible={false}
            text="5"
            glyph={5}
            fontFamily={fontFamily}
            fontSize={fontSize}
            padding={glyphPadding}
            ref={glyph5Ref}/>
      <Text visible={false}
            text="6"
            glyph={6}
            fontFamily={fontFamily}
            fontSize={fontSize}
            padding={glyphPadding}
            ref={glyph6Ref}/>
      <Text visible={false}
            text="7"
            glyph={7}
            fontFamily={fontFamily}
            fontSize={fontSize}
            padding={glyphPadding}
            ref={glyph7Ref}/>
      <Text visible={false}
            text="8"
            glyph={8}
            fontFamily={fontFamily}
            fontSize={fontSize}
            padding={glyphPadding}
            ref={glyph8Ref}/>
      <Text visible={false}
            text="9"
            glyph={9}
            fontFamily={fontFamily}
            fontSize={fontSize}
            padding={glyphPadding}
            ref={glyph9Ref}/>
    </Group>
  );
};

export default forwardRef(CardStack_);

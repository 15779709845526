import React, { useEffect, useState, useReducer, useRef } from 'react';
import { Stage, Layer } from 'react-konva';

import { Cube, Square, Bar, Bead } from './components/Bead';
import CardStack from './components/CardStack';
import BeadTray from './components/BeadTray';
import Rug from './components/Rug';

import 'normalize-css';
import 'milligram';
import './App.css';

////////////////////////////////////////////////////////////////////////////////
// Helpers

function getBounds() {
  const width = window.innerWidth;
  const height = window.innerHeight;

  var trayHeight = height * 0.3;
  var boxWidth = Math.min(trayHeight, width/4);
  var boxHeight = boxWidth;
  var trayWidth = boxWidth * 4;

  var rugHeight = height - trayHeight;

  var boxMargin = Math.max(0, (width - trayWidth) / 2);
  var boxPadding = boxWidth * 0.05;

  var fontSize = 60;

  var boxBounds = [...Array(4)].map((_, i) => {
    const x = boxMargin + i * boxWidth;
    return {
      x: x,
      y: 0,
      width: boxWidth,
      height: boxHeight,
      innerX: x + boxPadding,
      innerY: boxPadding,
      innerWidth: boxWidth - 2*boxPadding,
      innerHeight: boxHeight - 2*boxPadding
    };
  });

  // If we're in portrait, orient in a 2x2 grid instead
  if (width <= height) {
    trayHeight = Math.min(width, height * 0.4);
    boxWidth = trayHeight / 2;
    boxHeight = boxWidth;
    trayWidth = 2 * boxWidth;

    boxMargin = Math.max(0, (width - trayWidth) / 2);

    boxBounds = [...Array(4)].map((_, i) => {
      const x = boxMargin + (i%2) * boxWidth;
      // Math.round(i/4) is dumb, but whatever
      const y = Math.round(i/4) * boxWidth;
      return {
        x: x,
        y: y,
        width: boxWidth,
        height: boxHeight,
        innerX: x + boxPadding,
        innerY: y + boxPadding,
        innerWidth: boxWidth - 2*boxPadding,
        innerHeight: boxHeight - 2*boxPadding
      };
    });
  }


  return {
    width: width,
    height: height,

    trayHeight: trayHeight,
    rugHeight: rugHeight,

    boxWidth: boxWidth,
    boxHeight: boxHeight,
    trayWidth: trayWidth,
    boxMargin: boxMargin,
    boxPadding: boxPadding,

    boxBounds: boxBounds,

    fontSize: fontSize
  };
}

function generateCardValue() {
  const value = {1000: Math.ceil(Math.random() * 9) * 1000,
                 100: Math.ceil(Math.random() * 9) * 100,
                 10: Math.ceil(Math.random() * 9) * 10,
                 1: Math.ceil(Math.random() * 9)
                };

  return {...value,
          total: value[1000] + value[100] + value[10] + value[1]};
}

function generateBeadOrder() {
  var order = [];
  [...Array(9)].map((_,i) => order.push({type: "cube", id: `cube${i}`, i: (8-i), count: 9}));
  [...Array(9)].map((_,i) => order.push({type: "square", id: `square${i}`, i: (8-i), count: 9}));
  [...Array(9)].map((_,i) => order.push({type: "bar", id: `bar${i}`, i: (8-i), count: 9}));
  [...Array(9)].map((_,i) => order.push({type: "bead", id: `bead${i}`, i: (8-i), count: 9}));

  return order;
}

function moveBeadToTop(order, beadId) {
  const idx = order.findIndex((e) => e.id === beadId);

  var newOrder = order.slice();
  newOrder.push(newOrder[idx]);
  newOrder.splice(idx, 1);

  return newOrder;
}

////////////////////////////////////////////////////////////////////////////////
// Reducer

function reducer(state, action) {
  console.log(state, action);
  switch (action.type) {
  case "add-bead":
    return {
      ...state,
      total: state.total + action.value
    };

  case "remove-bead":
    return {
      ...state,
      total: state.total - action.value
    };

  case "move-bead-to-top":
    return {
      ...state,
      beadOrder: moveBeadToTop(state.beadOrder, action.value)
    };

  case "check-work":
    return {
      ...state,
      checkResult: state.total === state.cardValue.total
    };

  case "reset-check":
    return {
      ...state,
      checkResult: null
    };

  default:
    return state;
  };
}

////////////////////////////////////////////////////////////////////////////////
// Components

function App() {
  const [state, dispatch] = useReducer(reducer,
                                       {total: 0,
                                        cardValue: generateCardValue(),
                                        beadOrder: generateBeadOrder()});
  const parent = useRef(null);
  const [cardStackHeight, setCardStackHeight] = useState(0);
  const cardStack = useRef(null);
  const checkWorkButton = useRef(null);
  const [bounds, setBounds] = useState(getBounds());

  useEffect(() => {
    window.addEventListener("resize", () => {
      setBounds(getBounds());
    });

    const mqString = `(resolution: ${window.devicePixelRatio}dppx)`;
    window.matchMedia(mqString).addListener(() => {
      setBounds(getBounds());
    });
  }, []);

  useEffect(() => {
    checkWorkButton.current.style.top = `${cardStack.current.getClientRect().y + cardStackHeight + 20}px`;
    //    checkWorkButton.current.style.display = "checkResult" in state ? "block": "none";
    checkWorkButton.current.style.display = "block";
  }, [cardStack, cardStackHeight, bounds, state.checkResult]);

  return (
    <div className="app">
      <div className="stage-parent" ref={parent}>
        <div className="button-container">
          <button id="checkWork"
                  className="button"
                  style={{display: "none", top: 0}}
                  disabled={state.checkResult === true || state.checkResult === false}
                  ref={checkWorkButton}
                  onClick={() => dispatch({type: "check-work"})}>
            {state.checkResult === true ? "You did it!" :
             state.checkResult === false ? "Try again" :
             "Check my work"}
          </button>
        </div>
        <Stage width={bounds.width} height={bounds.height}>
          <Layer id="backgroundLayer">
            <BeadTray x={0}
                      y={0}
                      width={bounds.width}
                      height={bounds.trayHeight}/>

            <Rug x={0}
                 y={bounds.trayHeight}
                 width={bounds.width}
                 height={bounds.rugHeight}/>
          </Layer>
          <Layer id="cardLayer">
            <CardStack value={state.cardValue}
                       centerX={bounds.width/2}
                       y={bounds.height * 0.8}
                       fontSize={bounds.fontSize}
                       ref={cardStack}
                       setHeight={setCardStackHeight}/>
          </Layer>
          <Layer id="beadLayer">
            {state.beadOrder.map(({ type, id, i, count }) => {
              switch(type) {
              case "cube":
                return <Cube id={id}
                             key={id}
                             bounds={bounds}
                             boxIndex={0}
                             itemIndex={i}
                             itemCount={count}
                             dispatch={dispatch}/>;
              case "square":
                return <Square id={id}
                               key={id}
                               bounds={bounds}
                               boxIndex={1}
                               itemIndex={i}
                               itemCount={count}
                               dispatch={dispatch}/>;
              case "bar":
                return <Bar id={id}
                            key={id}
                            bounds={bounds}
                            boxIndex={2}
                            itemIndex={i}
                            itemCount={count}
                            dispatch={dispatch}/>;
              case "bead":
                return <Bead id={id}
                             key={id}
                             bounds={bounds}
                             boxIndex={3}
                             itemIndex={i}
                             itemCount={count}
                             dispatch={dispatch}/>;
              default:
                return null;
              };
            })}
          </Layer>
        </Stage>
      </div>
    </div>
  );
}

export default App;

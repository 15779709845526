import React from 'react';
import { Rect } from 'react-konva';
import useImage from 'use-image';

function Rug({ x, y, width, height }) {
  const [img] = useImage("/img/fabric.png");
  return (
    <Rect name="rug"
          fillPatternImage={img}
          x={x}
          y={y}
          width={width}
          height={height}/>
  );
}

export default Rug;

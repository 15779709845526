import React from 'react';
import { Label, Tag, Text, Group } from 'react-konva';

function splitValue(value) {
  return [Math.floor(value/1000),
          Math.floor((value % 1000) / 100),
          Math.floor((value % 100) / 10),
          value % 10];
};

function Digit({ value, magnitude, largestDims, visible, color, fontFamily, fontSize }) {
  return (
    <Label x={largestDims.width * (3 - magnitude)}
           width={largestDims.width}>
      <Tag fill="#fff"
           visible={visible}/>
      <Text align="center"
            text={value}
            visible={visible}
            fontFamily={fontFamily}
            fontSize={fontSize}
            fill={color}
            padding={2}
            width={largestDims.width}
            offsetY={-4} />
    </Label>
  );
}

function Card({ value, magnitude, largestDims, fontFamily, fontSize }) {
  const colors = ["#008800",
                  "#000088",
                  "#880000",
                  "#008800"];

  const [thousands, hundreds, tens, ones] = splitValue(value);

  return (
    <Group x={0}
           y={0}
           visible={magnitude !== 3 || value > 0}>
      <Digit value={thousands}
             magnitude={3}
             visible={thousands > 0 && magnitude === 3}
             largestDims={largestDims}
             color={colors[magnitude]}
             fontFamily={fontFamily}
             fontSize={fontSize} />
      <Digit value={hundreds}
             magnitude={2}
             visible={magnitude >= 2}
             largestDims={largestDims}
             color={colors[magnitude]}
             fontFamily={fontFamily}
             fontSize={fontSize} />
      <Digit value={tens}
             magnitude={1}
             visible={magnitude >= 1}
             largestDims={largestDims}
             color={colors[magnitude]}
             fontFamily={fontFamily}
             fontSize={fontSize} />
      <Digit value={ones}
             magnitude={0}
             visible={true}
             largestDims={largestDims}
             color={colors[magnitude]}
             fontFamily={fontFamily}
             fontSize={fontSize} />
    </Group>
  );
}

export default Card;

import React from 'react';
import { Rect } from 'react-konva';
import useImage from 'use-image';

function BeadTray({ x, y, width, height }) {
  const [img] = useImage("/img/retina_wood.png");
  return (
    <Rect name="tray"
          fillPatternImage={img}
          fillPatternRepeat="repeat"
          x={x}
          y={y}
          width={width}
          height={height}
          stroke="rgba(90,80,64,0.2)"
          strokeWidth={2}/>
  );
}

export default BeadTray;

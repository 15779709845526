import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

import './Fonts.css';

const WebFont = require('webfontloader');

/*global fontLoader*/
/*eslint no-undef: "error"*/
WebFont.load({
  custom: {
    families: ["KGPrimaryPenmanship"]
  },
  active: () => fontLoader.succeeded("KGPrimaryPenmanship"),
  inactive: () => fontLoader.failed()
});

ReactDOM.render(
  <React.StrictMode>
  <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import React, { useState, useRef, useEffect } from 'react';
import { Image } from 'react-konva';
import Konva from 'konva';
import useImage from 'use-image';

function calcBaseScale(boxBounds, imageSize, dragScale) {
  // Take dragScale into account, so we don't size it up higher than the image
  // size when dragging
  const xScale = boxBounds.innerWidth / (imageSize.width * dragScale);
  const yScale = boxBounds.innerHeight / (imageSize.height * dragScale);

  // console.log("scale", Math.min(xScale, yScale, 1/dragScale), "bounds", boxBounds.innerWidth);
  return Math.min(xScale, yScale, 1/dragScale);
}

/*
 * Position an item along an axis, given the amount of leftover space in the
 * container and the size of the item.
 */
function positionOnAxis(containerSize, itemSize, index, count) {
  console.assert(itemSize <= containerSize, "item size is larger than box size");
  // containerSize = 11, itemSize = 5, count = 3, leftover = -4
  // (expanded since we can't overlap in textual form)
  //      *****......
  //      ...*****...
  //      ......*****

  // containerSize = 11, itemSize = 5, count = 4, leftover = -4
  //      *****......
  //      ..*****....
  //      ....*****..
  //      ......*****

  const leftover = containerSize - (itemSize * count);

  if (leftover > 0) {
    // containerSize = 18, itemSize = 2, count = 3, leftover = 12
    // margin = 3
    // index:   0    1    2
    //       ...**...**...**...

    // total space split into count+1 spaces, to surround the count items
    const margin = leftover / (count + 1);
    return margin * (index + 1) + itemSize * index;
  } else if (leftover === 0) {
    // first and last are flush left and right, and the others are distributed evenly,
    // perfectly aligned
    const margin = itemSize;
    return index * margin;
  } else {
    // first and last are flush left and right, and the others are distributed evenly
    // across the space to its left (containerSize - itemSize).
    const margin = (containerSize - itemSize) / (count - 1);
    return index * margin;
  }
}

/*
 * Calculate the position of the `index`th of `count` units, centered and
 * distributed evenly inside `boxBounds`.
 */
function positionUnit(boxBounds, unitSize, index, count) {
  return {
    x: boxBounds.innerX +
      positionOnAxis(boxBounds.innerWidth, unitSize.width, index, count),
    y: boxBounds.innerY +
      positionOnAxis(boxBounds.innerHeight, unitSize.height, index, count),
  };
}

function fixPositionAfterResize(bounds, position, scaledImageSize) {
  var newPos = {};

  // Keep the unit entirely inside the bounds, or leftmost, if nothing else
  newPos.x = Math.max(Math.min(position.x, bounds.width - scaledImageSize.width), 0);
  // We assume that we only fix positions for units on the rug, so make sure it stays there
  newPos.y = Math.max(Math.min(position.y, bounds.height - scaledImageSize.height),
                      bounds.trayHeight);

  return newPos;
}

function isPositionOnRug(position, scaledImageSize, bounds) {
  return position.y + scaledImageSize.height/2 > bounds.trayHeight;
}

function calculateDragPosition(unitCenter, mousePosition) {
  return {x: mousePosition.x - unitCenter.x,
          y: mousePosition.y - unitCenter.y};
}

function Unit({ id, name, value,
                image, bounds, boxIndex, dispatch,
                baseScale, dragScale, zIndex,
                itemIndex, itemCount,
                hitShapePoints }) {
  const [img] = useImage(image);

  // Whether the unit is placed on the rug
  const [isOnRug, setIsOnRug] = useState(false);

  // Top left position of the unit
  const [position, setPosition] = useState({x: 0, y: 0});

  const [holdPosition, setHoldPosition] = useState({x: 0, y: 0});

  const bead = useRef(null);

  const imageSize = img ? {width: img.width, height: img.height} : {width: 0, height: 0};
  const scaledImageSize = {width: imageSize.width * baseScale, height: imageSize.height * baseScale};

  // Offset centers the image so that scaling up during drag happens from the center
  const offset = {x: imageSize.width / 2,
                  y: imageSize.height / 2};

  var animatePickup = node => {
    node.setAttrs({
      scaleX: dragScale * baseScale,
      scaleY: dragScale * baseScale
    });
  };

  var animateDrop = node => {
    node.to({
      duration: 0.1,
      easing: Konva.Easings.StrongEaseOut,
      scaleX: baseScale,
      scaleY: baseScale
    });
  };

  var handleTouchStart = e => {
    var stage = e.target.getStage();

    animatePickup(e.target);
    dispatch({type: "move-bead-to-top", value: id});
    dispatch({type: "reset-check", value: id});
    setHoldPosition(calculateDragPosition({x: e.target.attrs.x, y: e.target.attrs.y},
                                          stage.getPointerPosition()));
    e.target.findAncestor("Layer").draw();
  };

  var handleTouchEnd = e => {
    animateDrop(e.target);
    e.target.findAncestor("Layer").draw();
  };

  var handleMouseDown = e => {
    var stage = e.target.getStage();

    animatePickup(e.target);
    dispatch({type: "move-bead-to-top", value: id});
    dispatch({type: "reset-check", value: id});
    setHoldPosition(calculateDragPosition({x: e.target.attrs.x, y: e.target.attrs.y},
                                          stage.getPointerPosition()));
    e.target.findAncestor("Layer").draw();
  };

  var handleMouseUp = e => {
    animateDrop(e.target);
    e.target.findAncestor("Layer").draw();
  };

  var handleDragStart = e => {
    animatePickup(e.target);
  };

  var handleDragEnd = e => {
    var stage = e.target.getStage();
    var pos = stage.getPointerPosition();

    if (isPositionOnRug(pos, scaledImageSize, bounds)) {
      if (!isOnRug) {
        dispatch({type: "add-bead", value: value});
      }
      setIsOnRug(true);
    } else {
      if (isOnRug) {
        dispatch({type: "remove-bead", value: value});
      }
      setIsOnRug(false);
    }

    animateDrop(e.target);
    setPosition({x: pos.x - img.width * baseScale / 2 - holdPosition.x,
                 y: pos.y - img.height * baseScale / 2 - holdPosition.y});
  };

  const dragBounds = function(pos) {
    const halfWidth = img.width/2 * baseScale * dragScale;
    const halfHeight = img.height/2 * baseScale * dragScale;

    return {
      x: Math.max(halfWidth, Math.min(bounds.width - halfWidth, pos.x)),
      y: Math.max(halfHeight, Math.min(bounds.height - halfHeight, pos.y))
    };
  };

  hitShapePoints = hitShapePoints ||
                   (img ? [[0,0], [img.width,0], [img.width,img.height], [0,img.height]] :
                    [[0,0]]);

  useEffect(() => {
    bead.current.zIndex(zIndex);
  }, [zIndex]);

  useEffect(() => {
    if (img) {
      if (isOnRug) {
        // It was on the rug; make sure it stays in bounds.
        setPosition(fixPositionAfterResize(bounds, position, scaledImageSize));
      } else {
        // It was on the tray; reset it to the position it should be in with the new bounds.
        setPosition(positionUnit(bounds.boxBounds[boxIndex], scaledImageSize, itemIndex, itemCount));
      }

      setIsOnRug(isPositionOnRug(position, scaledImageSize, bounds));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [img, bounds, boxIndex]);

  return (
    <Image ref={bead}
           image={img}
           x={position.x + (img ? img.width * baseScale / 2 : 0)}
           y={position.y + (img ? img.height * baseScale / 2 : 0)}
           offset={offset}
           scaleX={baseScale}
           scaleY={baseScale}
           hitFunc={(context, shape) => {
             context.beginPath();
             context.moveTo(...hitShapePoints[0]);
             hitShapePoints.slice(1).forEach((p) => {
               context.lineTo(...p);
             });
             context.lineTo(...hitShapePoints[0]);
             context.closePath();
             context.fillShape(shape);
           }}
           draggable
           dragBoundFunc={dragBounds}
           onTouchStart={handleTouchStart}
           onTouchEnd={handleTouchEnd}
           onMouseDown={handleMouseDown}
           onMouseUp={handleMouseUp}
           onDragStart={handleDragStart}
           onDragEnd={handleDragEnd}/>

  );
}

function Cube({id, bounds, boxIndex, dispatch, itemIndex, itemCount}) {
  const imageSize = {
    width: 387,
    height: 450
  };

  const scale = calcBaseScale(bounds.boxBounds[boxIndex], imageSize, 1.1);

  return (
    <Unit id={id}
          name="cube"
          value={1000}
          image="/img/cube.png"
          bounds={bounds}
          boxIndex={boxIndex}
          baseScale={scale}
          dragScale={1.1}
          itemIndex={itemIndex}
          itemCount={itemCount}
          hitShapePoints={[[1,95],[145,0],[385,45],
                           [379,321],[259,449],[16,384],[1,128]]}
          dispatch={dispatch}/>
  );
}

function Square({id, bounds, boxIndex, dispatch, itemIndex, itemCount}) {
  const imageSize = {
    width: 419,
    height: 346
  };

  const scale = calcBaseScale(bounds.boxBounds[boxIndex], imageSize, 1.2);

  return (
    <Unit id={id}
          name="square"
          value={100}
          image="/img/square.png"
          bounds={bounds}
          boxIndex={boxIndex}
          baseScale={scale}
          dragScale={1.2}
          itemIndex={itemIndex}
          itemCount={itemCount}
          hitShapePoints={[[0,156],[0,139],[246,0],[262,0],
                           [419,176],[419,188],[165,346],[146,346]]}
          dispatch={dispatch}/>
  );
}

function Bar({id, x, y, bounds, boxIndex, dispatch, itemIndex, itemCount}) {
  const imageSize = {
    width: 297,
    height: 76
  };

  const scale = calcBaseScale(bounds.boxBounds[boxIndex], imageSize, 1.2);

  return (
    <Unit id={id}
          name="bar"
          value={10}
          image="/img/bar.png"
          bounds={bounds}
          boxIndex={boxIndex}
          baseScale={scale}
          dragScale={1.2}
          itemIndex={itemIndex}
          itemCount={itemCount}
          hitShapePoints={[[1,52],[280,0],[292,0],[296,9],
                           [296,18],[288,28],[42,76],[8,76],
                           [0,68]]}
          dispatch={dispatch}/>
  );
}

function Bead({id, x, y, bounds, boxIndex, dispatch, itemIndex, itemCount}) {
  const imageSize = {
    width: 27,
    height: 27
  };

  // Artificially force the unit beads to shrink much earlier than 27x27px boxes.
  const scale = calcBaseScale(bounds.boxBounds[boxIndex], {width: imageSize.width*6, height: imageSize.height*6}, 1.2);

  return (
    <Unit id={id}
          name="bead"
          value={1}
          image="/img/bead.png"
          bounds={bounds}
          boxIndex={boxIndex}
          baseScale={scale}
          dragScale={1.2}
          itemIndex={itemIndex}
          itemCount={itemCount}
          dispatch={dispatch}/>
  );
}

export { Cube, Square, Bar, Bead, calcBaseScale, positionOnAxis, positionUnit };
